export const PROJECTS_KEY = "project_ids";
export const addOrUpdateProject = (project, id) => {
  console.log("🚀 ~ addOrUpdateProject ~ project:", project)
  let data = [];
  let _data = localStorage.getItem(PROJECTS_KEY);
  if (_data) {
    data = JSON.parse(_data);
  }
  

  let isIdRepeated = data.includes(id);
  if (!isIdRepeated) {
    data.push(id);
  }
 
  
  localStorage.setItem(PROJECTS_KEY, JSON.stringify(data));
  localStorage.setItem(id, JSON.stringify(project));
};

export const getAllProjects = () => {
  let arr = [];
  let data = [];
  let _data = localStorage.getItem(PROJECTS_KEY);
  if (_data) {
    data = JSON.parse(_data);
  }
  console.log("data: " , data);
  
  
  data.forEach((ele) => {
    console.log("localData", JSON.parse(localStorage.getItem(ele)));
    if (JSON.parse(localStorage.getItem(ele)).store) {
      
      arr.push({...JSON.parse(localStorage.getItem(ele)).tempProject,projectImage:JSON.parse(localStorage.getItem(ele)).projectImage || ""});
    }else{
      arr.push({...JSON.parse(localStorage.getItem(ele)), projectImage:JSON.parse(localStorage.getItem(ele)).projectImage || ""});
    }
  });
  return arr;
};
export const getProject = (id) => {
  if (JSON.parse(localStorage.getItem(id))?.store) {
    return JSON.parse(localStorage.getItem(id)).tempProject;
  }
  return JSON.parse(localStorage.getItem(id));
};

export const deleteProject = (id) => {
  
  const projectsSet = new Set(
    JSON.parse(localStorage.getItem(PROJECTS_KEY)) || []
    );
    
  projectsSet.delete(id);
  localStorage.setItem(PROJECTS_KEY, JSON.stringify(Array.from(projectsSet)));
  localStorage.removeItem(id);
};
