import Button from "../../components/Button";
import "./Landing.css";
import GoogleLanding from "../../../assets/GoogleLogin.svg";
import ArrowLanding from "../../../assets/arrow.svg";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "../../../hooks/useGoogleLogin";
import { useRecoilValue } from "recoil";
import { userDataAtom } from "../../../atoms/auth.atom";
import { Suspense, lazy } from "react";

const CarouselLazyLoad = lazy(() => import("./LandingCarousel"));

function Landing() {
  const navigate = useNavigate();
  const { onGoogleLogin } = useGoogleLogin();
  const userData = useRecoilValue(userDataAtom);

  return (
    <section>
      <div className="container">
        <div className="container pt-[50px] mb-[30px]">
          <h1 className="pb-[24px]  font-medium text-[24px] md:text-[28px] text-center text-[#22222A]">
            From
            <span className="text-4xl from-[#3F67AD] via-[#7084B3] to-[#FF8F94] bg-gradient-to-b bg-clip-text text-transparent">
              CodeyAi diagrams
            </span>
            To Fully
            <span className="text-4xl from-[#3F67AD] via-[#7084B3] to-[#FF8F94] bg-gradient-to-b bg-clip-text text-transparent">
              Implemented Project
            </span>
            with just few clicks.
          </h1>
          <p className="font-normal text-base max-w-full text-center ">
            Revolutionize your team's workflow with our cutting-edge
            diagram-to-code platform. Visualize complex ideas faster and
            collaborate like never before with Intelligent Programming
            Diagramming.
          </p>
        </div>
        {userData ? (
          <>
            <h4 className="font-medium text-center text-[#4068B0] text-base mb-[30px]">
              Hi, {`${userData?.f_name} ${userData?.l_name}`}
            </h4>
            <div className="element-center gap-[25px] flex-col sm:flex-row mb-[20px]">
              <Button
                onClick={() => navigate("/codeyai/projects")}
                className={`btn`}
              >
                <span>Go to your Projects</span>
              </Button>
            </div>
          </>
        ) : (
          <>
            <h4 className="font-medium text-center text-[#4068B0] text-base mb-[30px]">
              Get your free account today
            </h4>
            <div className="element-center gap-[25px] flex-col sm:flex-row mb-[20px]">
              <Button
                onClick={onGoogleLogin}
                className="btn element-center gap-[8px] bg-[#1E2F4C]"
              >
                <span>
                  <img src={GoogleLanding} alt="googleLogo" />
                </span>
                <span>Sign up with Google</span>
              </Button>
              <span className="text-[#7B7F95] font-normal text-sm text-center">
                Or
              </span>
              <Button
                className="btn element-center gap-[8px] px-[30px] sm:px-[28px]"
                onClick={() => navigate("/register")}
              >
                <span>Sign up with Email</span>
                <span>
                  <img src={ArrowLanding} alt="ArrowLanding" />
                </span>
              </Button>
            </div>
          </>
        )}
      </div>
      <div className="relative overflow-hidden  bottom-[-120px] mx-auto mt-[-120px] mb-[50px] text-center w-[343px] sm:w-[800px] max-w-full h-[400px] shrink-0 rounded-[10px] border border-[#3F67AD] bg-[#F4F5F7] ">
        <Suspense
          fallback={
            <div className="flex items-center justify-center h-full w-full">
              <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
            </div>
          }
        >
          <CarouselLazyLoad />
        </Suspense>
      </div>
    </section>
  );
}
export default Landing;
