import Button from "../../components/Button";
import emptyContent from "../../../assets/emptyContent.svg";
import smallBtn from "../../../assets/smallbtn.svg";

import projectBlock from "../../../assets/ProjectBlock.svg";

import PersonalProjects from "./PersonalProjects";
import { Link } from "react-router-dom";
import { useUserIdentity } from "../../../hooks/useUserIdentity";
import { Table } from "antd";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (_, record) => {
      console.log("record", record);

      return (
        <Link to={"/codeyai/diagram/" + (record?.tempProject?.id || record.id)}>
          {record?.tempProject?.metaData?.name || record.metaData?.name}
        </Link>
      );
    },
  },
  {
    title: "Project Type",
    dataIndex: "type",
    key: "type",
    render: (_, record) => {
      console.log("record", record);

      return (
        <Link to={"/codeyai/diagram/" + (record?.tempProject?.id || record.id)}>
          {record?.tempProject?.metaData?.type || record.metaData?.type}
        </Link>
      );
    },
  },
  {
    title: "Java Version",
    dataIndex: "javaVersion",
    key: "javaVersion",
    render: (_, record) => {
      return (
        <span>
          {record?.tempProject?.metaData?.javaVersion ||
            record.metaData?.javaVersion}
        </span>
      );
    },
  },
  {
    title: "description",
    dataIndex: "description",
    key: "description",
    render: (_, record) => {
      return (
        <span>
          {record?.tempProject?.metaData?.description ||
            record.metaData?.description}
        </span>
      );
    },
  },
];

const RecentProjects = ({
  projects,
  getProjects,
  setIsAddModalOpen,
  projectsView,
}) => {
  const { canAccessDiagram } = useUserIdentity();

  if (projects.length && canAccessDiagram) {
    return (
      <>
        {projectsView === "grid" ? (
          <div className="flex flex-wrap gap-6 max-w-full px-8 overflow-auto mb-24">
            {projects?.map?.((project) => {
              return (
                <div key={project?.tempProject?.id || project.id}>
                  <PersonalProjects
                    id={project?.tempProject?.id || project.id}
                    getProjects={getProjects}
                    image={
                      project?.projectImage
                        ? project.projectImage
                        : projectBlock
                    }
                    title={
                      project?.tempProject?.metaData?.name ||
                      project.metaData?.name
                    }
                    desc={
                      project?.tempProject?.metaData?.description ||
                      project.metaData?.description
                    }
                  />
                </div>
              );
            })}
          </div>
        ) : (
          <Table
            dataSource={projects}
            columns={columns}
            className="mx-10 my-5"
            bordered
          />
        )}
      </>
    );
  }
  return (
    <div className="element-center flex-col gap-8 h-full py-9 sm:py-0">
      <div>
        <img src={emptyContent} alt="content-img" />
      </div>
      <p className="text-[20px] font-medium">No Projects created yet</p>
      <Button
        onClick={() => setIsAddModalOpen(true)}
        className={`btn h-10 element-center gap-[10px] rounded-lg py-[10px] px-5`}
      >
        <span>
          <img src={smallBtn} alt="smallBtn" />
        </span>
        <span className="text-[14px] font-medium">New Project</span>
      </Button>
    </div>
  );
};

export default RecentProjects;
