export function MapperObject(pkg, store) {
  const newObj: any = {
    packageName: pkg?.packageName,
    parentPackageName: pkg?.parentPackageName ?? "",
    components:
      pkg?.components?.length > 0
        ? pkg.components
            ?.map((comp) => store.current[pkg?.packageName]?.current[comp.id])
            ?.filter((comp) => comp?.componentType !== "innerClass")
        : [],
    childrenPackages: [],
  };
  if (pkg?.childrenPackages && pkg.childrenPackages?.length > 0) {
    newObj.childrenPackages = pkg.childrenPackages.map((child) =>
      MapperObject(child, store)
    );
  }
  return newObj;
}


export function getAllComponents(pkg: any,componentId:string=null): any {
  let result: any[] = [];
  const componentsIds = new Set<string>(); // Use Set for unique IDs
// console.log("component id",componentId);

  // Helper function to process components
  const processComponents = (components: any[], packageName: string) => {
    components.forEach(({ data: { store: { current } }, ...component }) => {
      const dndnodeKeys = Object.keys(current).filter((key) =>
        key.startsWith("dndnode_")
      );

      dndnodeKeys
      // .filter((key) => key !== componentId)
      .forEach((key) => {
        if (!componentsIds.has(key)) {
          componentsIds.add(key); // Use Set to store unique keys
          result.push({
            ...current[key],
            packageName,
          });
        }
      });
    });
  };

  // Process current package components
  if (pkg.components && pkg.components.length > 0) {
    processComponents(pkg.components, pkg.packageName);
  }

  // Recursively process child packages
  if (pkg.childrenPackages && pkg.childrenPackages.length > 0) {
    pkg.childrenPackages.forEach((childPkg: any) => {
      const childResults = getAllComponents(childPkg,componentId);
      result.push(...childResults);
    });
  }

  return result;
}




export function replaceKey(obj, oldKeyName, newKeyName) {
  // Check if the object has the old key
  if (obj.hasOwnProperty(oldKeyName)) {
    if (oldKeyName === newKeyName) {
      return;
    }
    // Create a new property with the new key name and assign the value of the old key
    obj[newKeyName] = obj[oldKeyName];
    // Delete the old key
    delete obj[oldKeyName];
  }
  // Return the modified object
  return obj;
}

export function debounce<T extends (...args: any[]) => void>(
  func: T,
  delay: number
): T {
  let timeoutId: NodeJS.Timeout;

  return function (this: ThisParameterType<T>, ...args: Parameters<T>) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  } as T;
}