import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Link } from "react-router-dom";
import { deleteProject } from "../fakeApi";
import del from "../../../assets/del.png";

const PersonalProjects = ({ image, title, desc, getProjects, id }) => {
  const onDeleteProject = (id) => {
    deleteProject(id);
    getProjects();
  };

  // console.log(`Base64 Size: ${(image.length * 3) / 4 / 1024} KB`);

  return (
    <div className="relative px-2 pr-14 border py-2 rounded-lg hover:cursor-pointer hover:scale-x-[102%]">
      <button
        onClick={() => onDeleteProject(id)}
        className="absolute top-3 right-3 z-40"
      >
        <img className="w-[35px] h-[35px]" src={del} />
      </button>
      <Link to={"/codeyai/diagram/" + id}>
        <div className="flex justify-center  flex-col gap-4 w-full min-w-full overflow-hidden ">
          <LazyLoadImage
            className="w-[20rem] h-[12rem] border rounded-lg "
            src={image}
            alt="projectImage"
            effect="blur"
          />
          <div className="flex flex-col gap-2 py-0  items-start">
            <h4 className="text-lg font-medium">{title}</h4>
            <p className="text-[11px] font-normal text-[#7B7F95]">{desc}</p>
          </div>
        </div>
      </Link>
    </div>
  );
};
export default PersonalProjects;
